import React from 'react';
import { BoardEntity } from '../../entities';
import './header-board.scss';

interface Props {
  boardColumns: BoardEntity[];
}

export default function index(props: Props) {
  const { boardColumns } = props;
  return (
    <div className="header-column-board">
      {boardColumns.map((item, index) => {
        return (
          <div key={index} className="header-board-column-item">
            {item.title}
          </div>
        );
      })}
    </div>
  );
}
