import React, { useEffect, useMemo, useRef, useState } from 'react';
import { capitalizeEachWord } from '@base/helpers';
import { BaseChartEntity } from './base-chart.entity';
import { dateFormatter } from '@base/helpers';
import { Badge } from 'antd';

// AG Grid React components & library
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

// AG Grid styles
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

interface Props extends BaseChartEntity {
  [key: string]: any;
}

const KeyIndexDate = ['created', 'created_at', 'last_update'];

export default function Table(props: Props) {
  const { isReadyToShow } = props;
  const [rowData, setRowData] = useState([]);
  const query = props.query;
  const gridRef: any = useRef();

  useEffect(() => {
    const resultSet = props?.resultResponseQuery;
    const isReadyToShow = props?.isReadyToShow;
    if (resultSet && isReadyToShow) {
      setRowData(
        resultSet.tablePivot().map((row) =>
          Object.keys(row).reduce((object, key) => {
            const dataIndex = key.split('.')[1];
            let value = row[key];
            const isDate = KeyIndexDate.includes(dataIndex) || dataIndex?.includes('date');
            if (isDate) value = dateFormatter(value).toLocal();

            return {
              ...object,
              [key.replace('.', '-')]: value,
            };
          }, {}),
        ),
      );
    }
  }, [props]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: 'columns',
    };
  }, []);

  function renderStatus(value: any): any {
    const StatusConfig = [
      { status: 'done', color: 'green' },
      { status: 'open', color: 'geekblue' },
      { status: 'in_process', color: 'geekblue' },
      { status: 'waiting', color: 'yellow' },
      { status: 'cancel', color: 'red' },
      { status: 'closed', color: 'red' },
      { status: 'draft', color: '#ccc' },
    ];

    return (
      <Badge
        color={StatusConfig.find((item) => item.status === value)?.color ?? ''}
        text={capitalizeEachWord(value?.split('_')?.join(' '))}
      />
    );
  }

  function customRender(props) {
    const value = props?.value;
    const dataIndex: string = props?.colDef?.field?.split('-')[1];
    const isStatus = dataIndex?.toLowerCase() === 'status';

    if (isStatus) return renderStatus(value);
    return value ?? null;
  }

  function makeColumnDefs(query: any): any {
    if (!query) return [];
    const measures = query?.measures ?? [];
    const dimensions = query?.dimensions ?? [];
    const columnConfigs = [...dimensions, ...measures];
    const labelColumns = columnConfigs.map((fieldItem) =>
      capitalizeEachWord(fieldItem.split('.')[1].split('_').join(' ')),
    );

    return columnConfigs.map((fieldItem) => {
      let headerName = capitalizeEachWord(fieldItem.split('.')[1].split('_').join(' '));
      const totalHeaderName = labelColumns.filter((item) => item === headerName)?.length;
      if (totalHeaderName > 1) {
        const prevTitle = fieldItem
          .split('.')[0]
          .split(/(?=[A-Z])/)
          .splice(1)
          .join(' ');

        const realTitle = fieldItem.split('.')[1].split('_').join(' ');
        headerName = capitalizeEachWord(`${prevTitle} ${realTitle}`);
      }

      const field = fieldItem.replace('.', '-');
      const name = field.replace('-', '.');
      const isDimension = Object.values(dimensions).indexOf(name) !== -1;
      const isMeasure = Object.values(measures).indexOf(name) !== -1;

      return {
        headerName,
        field,
        enablePivot: true,
        enableRowGroup: isDimension,
        enableValue: isMeasure,
        pivot: headerName === 'status',
        rowGroup: headerName === 'name',
        allowedAggFuncs: ['sum', 'max', 'avg', 'min'],
        aggFunc: isMeasure ? 'sum' : null,
        cellRenderer: customRender,
      };
    });
  }

  return (
    <div className="ag-theme-balham" style={{ height: 700 }}>
      <AgGridReact
        ref={gridRef}
        sideBar={sideBar}
        rowData={rowData}
        columnDefs={makeColumnDefs(query)}
        autoGroupColumnDef={{ minWidth: 250 }}
        defaultColDef={{
          flex: 1,
          minWidth: 150,
          sortable: true,
          resizable: true,
        }}
        aggFuncs={{
          min: ({ values }) => values.reduce((min, value) => Math.min(min, Number(value)), 0),
          max: ({ values }) => values.reduce((max, value) => Math.max(max, Number(value)), 0),
          sum: ({ values }) => values.reduce((sum, value) => sum + Number(value), 0),
          avg: ({ values }) => (values.reduce((sum, value) => sum + Number(value), 0) / values.length).toFixed(0),
        }}
      />
    </div>
  );
}
