import React from 'react';
import { BaseChartEntity } from './base-chart.entity';
interface Props extends BaseChartEntity {
  [key: string]: any;
}

export default function Indicator(props: Props) {
  const { resultQuery } = props;
  return <div>Indicator</div>;
}
