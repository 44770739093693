import { atom } from 'recoil';

export const activeMenuDashboard = atom({
  key: 'activeMenuDashboard',
  default: undefined,
});

export const activeMenuDashboardMJS = atom({
  key: 'activeMenuDashboardMJS',
  default: undefined,
});

export const activeMenuProject = atom({
  key: 'activeMenuProject',
  default: undefined,
});
