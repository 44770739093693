import React, { useEffect } from 'react';
import { PageHeader, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Tabs, Layout } from 'antd';
const { TabPane } = Tabs;

import ModuleData from './components/module-data';
import { activeMenuDashboard, activeMenuProject, activeMenuDashboardMJS } from './atom';
import { useRecoilState } from 'recoil';
import { OptionsModule, OptionsModuleMJS } from './options';
export default function index() {
  const history = useHistory();

  const [activeTabMenuProject, setActiveTabMenuProject] = useRecoilState(activeMenuProject);
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuDashboard);
  const [activeMenuMJS, setActiveMenuMJS] = useRecoilState(activeMenuDashboardMJS);

  function gotoAddChat() {
    history.push(`/dashboard/${activeTabMenuProject}/create`);
  }

  const ListMenu = OptionsModule;
  const ListMenuMJS = OptionsModuleMJS;

  useEffect(() => {
    if (!activeMenu) setActiveMenu(ListMenu[0].name);
  }, [ListMenu, activeMenu]);

  useEffect(() => {
    if (!activeMenuMJS) setActiveMenuMJS(ListMenuMJS[0].name);
  }, [ListMenuMJS, activeMenuMJS]);

  useEffect(() => {
    if (!activeTabMenuProject) setActiveTabMenuProject('pmps');
  }, [activeTabMenuProject]);

  return (
    <React.Fragment>
      <Tabs activeKey={activeTabMenuProject} onChange={(value) => setActiveTabMenuProject(value)}>
        <TabPane tab="PMPS" key="pmps">
          <PageHeader
            ghost={false}
            title="Dashboard"
            extra={[
              <Button key="1" type="primary" onClick={() => gotoAddChat()}>
                Add Chart
              </Button>,
            ]}
          />
          <Layout.Content style={{ backgroundColor: '#fff', padding: '20px' }}>
            <Tabs
              tabPosition="left"
              activeKey={activeMenu}
              onChange={(value) => setActiveMenu(value)}
              style={{ maxHeight: '70vh' }}
            >
              {ListMenu.map((item, index) => {
                return (
                  <TabPane tab={item.label} key={item.name}>
                    <ModuleData activeTabMenuProject={activeTabMenuProject} moduleName={item.name} />
                  </TabPane>
                );
              })}
            </Tabs>
          </Layout.Content>
        </TabPane>
        <TabPane tab="MJS" key="mjs">
          <PageHeader
            ghost={false}
            title="Dashboard"
            extra={[
              <Button key="1" type="primary" onClick={() => gotoAddChat()}>
                Add Chart
              </Button>,
            ]}
          />
          <Layout.Content style={{ backgroundColor: '#fff', padding: '20px' }}>
            <Tabs
              tabPosition="left"
              activeKey={activeMenuMJS}
              onChange={(value) => setActiveMenuMJS(value)}
              style={{ maxHeight: '70vh' }}
            >
              {ListMenuMJS.map((item, index) => {
                return (
                  <TabPane tab={item.label} key={item.name}>
                    <ModuleData activeTabMenuProject={activeTabMenuProject} moduleName={item.name} />
                  </TabPane>
                );
              })}
            </Tabs>
          </Layout.Content>
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}
