import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, CubeLoader, FatalError } from '../../atoms';
import SectionRow from '../SectionRow';
import {
  CodeOutlined,
  CodeSandboxOutlined,
  CopyOutlined,
  DownOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Modal, Row, Col, Form } from 'antd';
import SelectChartType from '..//SelectChartType';
import Settings from '../Settings';
import ChartBuilder from './chart-builder';
import AdditionalFilter from './additional-filter';
import PrismCode from '../PrismCode';

const StyledCard: any = styled(Card)`
  min-height: 420px;

  .ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: transparent;
  }

  .ant-card-body {
    max-width: 100%;
    overflow: auto;
    position: relative;
  }
`;

export default function index(props: any) {
  const {
    query,
    error,
    metaError,
    meta,
    isQueryPresent,
    chartType,
    updateChartType,
    measures,
    updateMeasures,
    dimensions,
    updateDimensions,
    segments,
    updateSegments,
    filters,
    updateFilters,
    timeDimensions,
    updateTimeDimensions,
    orderMembers,
    updateOrder,
    pivotConfig,
    updatePivotConfig,
    missingMembers,
    isFetchingMeta,
    dryRunResponse,
    availableMembers,
    availableFilterMembers,
    defaultValue,
    additionalFilter = [],
    availableFilters,
  } = props;
  const [showCode, setShowCode] = useState('preview');
  const extra = (
    <SectionRow>
      {chartType === 'table' && (
        <Button
          data-testid="chart-btn"
          size="small"
          type={!showCode ? 'primary' : 'default'}
          disabled={isFetchingMeta}
          onClick={() => {
            setShowCode('additionalFilter');
          }}
        >
          Additional FIlter
        </Button>
      )}

      <Button.Group>
        <Button
          data-testid="chart-btn"
          size="small"
          type={!showCode ? 'primary' : 'default'}
          disabled={isFetchingMeta}
          onClick={() => {
            setShowCode('preview');
          }}
        >
          Preview
        </Button>

        <Button
          data-testid="json-query-btn"
          size="small"
          type={showCode === 'query' ? 'primary' : 'default'}
          disabled={isFetchingMeta}
          onClick={() => {
            setShowCode('query');
          }}
        >
          JSON Query
        </Button>
      </Button.Group>
    </SectionRow>
  );
  let title;

  if (showCode === 'query') {
    title = (
      <SectionRow>
        <div>JSON Query</div>
      </SectionRow>
    );
  } else if (showCode === 'additionalFilter') {
    title = (
      <SectionRow>
        <div>Additional Filter</div>
      </SectionRow>
    );
  } else {
    title = (
      <SectionRow>
        <div>Preview</div>
        <SelectChartType
          chartType={chartType || 'line'}
          updateChartType={(type) => {
            updateChartType(type);
          }}
        />
        <Settings
          isQueryPresent={isQueryPresent}
          limit={query.limit || 100}
          pivotConfig={pivotConfig}
          disabled={isFetchingMeta}
          orderMembers={orderMembers}
          onReorder={updateOrder.reorder}
          onOrderChange={updateOrder.set}
          onMove={updatePivotConfig.moveItem}
          onUpdate={updatePivotConfig.update}
          chartType={chartType}
        />
      </SectionRow>
    );
  }

  const queryText = JSON.stringify({ query, additionalFilter }, null, 2);

  return (
    <StyledCard title={title} extra={extra}>
      {!isQueryPresent && metaError && <FatalError error={metaError} />}
      {!isQueryPresent && !metaError && (
        <h3 style={{ textAlign: 'center' }}>Choose a measure or dimension to get started</h3>
      )}
      {isQueryPresent && showCode === 'preview' && <ChartBuilder {...props} defaultValue={defaultValue} />}
      {isQueryPresent && showCode === 'query' && <PrismCode code={queryText} />}

      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          return (
            <Form.Item name="additionalFilter">
              {isQueryPresent && showCode === 'additionalFilter' && (
                <AdditionalFilter availableFilters={availableFilters} {...props} code={queryText} />
              )}
            </Form.Item>
          );
        }}
      </Form.Item>
    </StyledCard>
  );
}
