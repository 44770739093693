import React from 'react';
import { Badge, Card, Col, Row, Avatar, Tag } from 'antd';
const { Meta } = Card;
import { Menu, Dropdown } from 'antd';
import {
  EditOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  CalendarOutlined,
  CopyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { CardEntity, DataSourceEntity } from '../../entities';
import { useBoardContextValue } from '../../context';

interface Props {
  cardItem: CardEntity;
  groupItem: DataSourceEntity;
}

export default function index(props: Props) {
  const { cardItem, groupItem } = props;

  const {
    keyTitleCard = 'code',
    keyBodyCard = 'description',
    renderTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    makeColorBadgeCard,
    keyTitleBadgeCard = 'title',
    dataOnDrawer,
    onCloseShowCard,
    onShowCard,
  } = useBoardContextValue();

  function TitleCard(row: any): React.ReactNode {
    if (renderTitleCard) return renderTitleCard(row);
    return row[keyTitleCard];
  }

  function BodyCard(row: any): React.ReactNode {
    if (renderBodyCard) return renderBodyCard(row);
    return row[keyBodyCard];
  }

  function TitleBadgeCard(row: any): React.ReactNode {
    if (renderTitleBadgeCard) return renderTitleBadgeCard(row);
    return row[keyTitleBadgeCard];
  }

  function ColorBadge(row: any): string {
    if (makeColorBadgeCard) return makeColorBadgeCard(row);
    return '#108ee9';
  }

  function checkButtonShow(content): boolean {
    if (!dataOnDrawer) return true;
    else if (dataOnDrawer.id !== content.id) return true;
    return false;
  }

  function checkButtonCloseShow(content): boolean {
    if (dataOnDrawer && content && dataOnDrawer?.id === content.id) return true;
    return false;
  }

  function menuAction(content: any): JSX.Element {
    return (
      <Menu style={{ width: '200px' }} onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item icon={<EditOutlined />} key="0" onClick={(info) => console.log({ info })}>
          Edit
        </Menu.Item>
        <Menu.Item icon={<DeleteOutlined />} key="1" onClick={(info) => console.log({ info })}>
          Delete
        </Menu.Item>
        <Menu.Item icon={<CopyOutlined />} key="3" onClick={(info) => console.log({ info })}>
          Duplicate
        </Menu.Item>
        <Menu.Divider />
        {checkButtonCloseShow(content) && (
          <Menu.Item icon={<EyeInvisibleOutlined />} key="4" onClick={() => onCloseShowCard(content, groupItem)}>
            Close details
          </Menu.Item>
        )}
        {checkButtonShow(content) && (
          <Menu.Item icon={<EyeOutlined />} key="4" onClick={() => onShowCard(content, groupItem)}>
            Show details
          </Menu.Item>
        )}
      </Menu>
    );
  }

  return (
    <div className="card-item">
      <Card size="small">
        <div className="title-content">
          <Tag color={ColorBadge(cardItem)}>{TitleBadgeCard(cardItem)}</Tag>
          <span className="title-ticket">{TitleCard(cardItem)}</span>
        </div>
        <div className="body-content">{BodyCard(cardItem)}</div>
        <div className="footer-content">
          <Row>
            <Col span={4}>
              <Meta key="1" avatar={<Avatar size="small" src="https://joeschmoe.io/api/v1/random" />} />
            </Col>
            <Col span={17}>
              <div className="due-date-content">
                <CalendarOutlined style={{ marginRight: '5px' }} />
                {moment().format('ll')}
              </div>
            </Col>
            <Col span={3}>
              <div className="action-content" onClick={(e) => e.stopPropagation()}>
                <Dropdown overlay={() => menuAction(cardItem)} trigger={['click']}>
                  <div
                    style={{
                      width: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      height: '25px',
                    }}
                  >
                    <EllipsisOutlined />
                  </div>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      {/* </Badge.Ribbon> */}
    </div>
  );
}
