import React, { useState } from 'react';
import { RecoilRoot } from 'recoil';
import { AppRouter } from '@main/routes/app-route';

function App(): JSX.Element {
  return (
    <RecoilRoot>
      <AppRouter />
    </RecoilRoot>
  );
}

export default App;
