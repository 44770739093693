import React from 'react';
import { Layout, Menu, Breadcrumb, Space } from 'antd';
const { Footer } = Layout;
import { QuestionCircleOutlined } from '@ant-design/icons';

import './auth-layout.less';

interface AuthLayoutProps {
  children?: JSX.Element;
}
export default function index(props: AuthLayoutProps) {
  const { children } = props;
  return (
    <Layout className="auth-layout">
      <Layout.Header className="auth-layout-header"></Layout.Header>
      <Layout.Content className="auth-layout-content">{children}</Layout.Content>
    </Layout>
  );
}
