import React, { useState } from 'react';
import { Card, Avatar, Badge, Row, Col, Button } from 'antd';
import Board, { moveCard } from '@asseinfo/react-kanban';
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  CalendarOutlined,
  CopyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
const { Meta } = Card;
import { Menu, Dropdown } from 'antd';

import { capitalizeEachWord } from '@base/helpers';

const board = {
  columns: [
    {
      id: 1,
      title: 'Backlog',
      cards: [
        {
          id: 1,
          code: 'ERP-001',
          due_date: moment(),
          type: 'task',
          title:
            'Default menampilkan data company & site & location & user, user ketika mengcreate data ( data master/ transaksi )',
          description:
            'Default menampilkan data company & site & location & user, user ketika mengcreate data ( data master/ transaksi )',
        },
        {
          id: 2,
          code: 'ERP-001',
          due_date: moment(),

          type: 'task',
          title: 'Card title 2',
          description: 'Card content',
        },
        {
          id: 3,
          code: 'ERP-001',
          due_date: moment(),

          type: 'task',
          title: 'Card title 3',
          description: 'Card content',
        },
      ],
    },
    {
      id: 2,
      title: 'Doing',
      cards: [
        {
          id: 9,
          code: 'ERP-001',
          due_date: moment(),

          type: 'bug',
          title: 'Card title 9',
          description: 'Card content',
        },
      ],
    },
    {
      id: 3,
      title: 'Q&A',
      cards: [
        {
          id: 10,
          code: 'ERP-001',
          due_date: moment(),

          type: 'bug',
          title: 'Card title 10',
          description: 'Card content',
        },
        {
          id: 11,
          code: 'ERP-001',
          due_date: moment(),

          type: 'task',
          title: 'Card title 11',
          description: 'Card content',
        },
      ],
    },
    {
      id: 4,
      title: 'Production',
      cards: [
        {
          id: 12,
          code: 'ERP-001',
          due_date: moment(),

          type: 'story',
          title: 'Card title 12',
          description: 'Card content',
        },
        {
          id: 13,
          code: 'ERP-001',
          type: 'task',
          due_date: moment(),

          title: 'Card title 13',
          description: 'Card content',
        },
      ],
    },
    {
      id: 5,
      title: 'Production',
      cards: [
        {
          id: 14,
          code: 'ERP-001',
          due_date: moment(),

          type: 'task',
          title: 'Card title 12',
          description: 'Card content',
        },
        {
          id: 15,
          code: 'ERP-001',
          type: 'task',
          due_date: moment(),

          title: 'Card title 13',
          description: 'Card content',
        },
      ],
    },
    {
      id: 6,
      title: 'Production',
      cards: [
        {
          id: 16,
          code: 'ERP-001',
          type: 'task',
          due_date: moment(),

          title: 'Card title 12',
          description: 'Card content',
        },
        {
          id: 17,
          code: 'ERP-001',
          type: 'task',
          due_date: moment(),

          title: 'Card title 13',
          description: 'Card content',
        },
      ],
    },
  ],
};

interface Props {
  withHeaderColumn: boolean;
  showDetail: boolean;
  onClickCard(props: any): void;
  cardContent: any;
  toggleDrawerDetail(): void;
}

export default function index(props: Props) {
  const { withHeaderColumn, showDetail, onClickCard, cardContent, toggleDrawerDetail } = props;
  const [controlledBoard, setBoard] = useState(board);

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
  }

  function makeColorBadge(type: string): string {
    if (type === 'bug') return '#f5222d';
    else if (type === 'story') return '#13c2c2';
    return '#108ee9';
  }

  function menuAction(content: any): JSX.Element {
    return (
      <Menu style={{ width: '200px' }} onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item icon={<EditOutlined />} key="0" onClick={(info) => console.log({ info })}>
          Edit
        </Menu.Item>
        <Menu.Item icon={<DeleteOutlined />} key="1" onClick={(info) => console.log({ info })}>
          Delete
        </Menu.Item>
        <Menu.Item icon={<CopyOutlined />} key="3" onClick={(info) => console.log({ info })}>
          Duplicate
        </Menu.Item>
        <Menu.Divider />
        {cardContent?.id !== content.id && (
          <Menu.Item icon={<EyeOutlined />} key="4" onClick={() => onClickCard({ content })}>
            Show details
          </Menu.Item>
        )}
        {cardContent?.id === content.id && (
          <Menu.Item icon={<EyeInvisibleOutlined />} key="4" onClick={() => toggleDrawerDetail()}>
            Close details
          </Menu.Item>
        )}
      </Menu>
    );
  }

  return (
    <div
      className={`kanban-wrapper ${showDetail ? 'kanban-wrapper-with-detail' : 'kanban-wrapper-without-detail'} ${
        withHeaderColumn ? '' : 'header-column-hidden'
      }`}
    >
      <Board
        onCardDragEnd={handleCardMove}
        disableColumnDrag
        renderCard={(content, { removeCard, dragging }) => {
          return (
            <div className={`custom-card-kanban  `}>
              <Badge.Ribbon text={capitalizeEachWord(content.type)} color={makeColorBadge(content.type)}>
                <Card
                  size="small"
                  title={content.code}
                  onClick={(e) => onClickCard({ content })}
                  className={`custom-card-content-kanban ${dragging ? 'custom-content-card-kanban--dragging' : ''}`}
                >
                  <div className="title-content">{content.title}</div>
                  <div className="body-content">
                    <Row>
                      <Col span={4}>
                        <Meta key="1" avatar={<Avatar size="small" src="https://joeschmoe.io/api/v1/random" />} />
                      </Col>
                      <Col span={17}>
                        <div className="due-date-content">
                          <CalendarOutlined style={{ marginRight: '5px' }} />
                          {moment(content.due_date).format('ll')}
                        </div>
                      </Col>
                      <Col span={3}>
                        <div className="action-content" onClick={(e) => e.stopPropagation()}>
                          <Dropdown overlay={() => menuAction(content)} trigger={['click']}>
                            <EllipsisOutlined />
                          </Dropdown>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Badge.Ribbon>
            </div>
          );
        }}
      >
        {controlledBoard}
      </Board>
    </div>
  );
}
