import React, { useState } from 'react';
import HeaderBoard from './components/header-board';
import SeparatorBody from './components/sparator-body';
import ColumnBoard from './components/column-board';
import { Collapse, Spin, Row, Col } from 'antd';
const { Panel } = Collapse;

import './style.scss';
import { BoardEntity, DataSourceEntity } from './entities';
import { BoardContextValueEntity, BoardProvider } from './context';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { LoadingOutlined } from '@ant-design/icons';
import HeaderDrawer from './components/drawer-header';
interface DragResultEntity {
  index: any;
  cardGroup: any;
  column: any;
}

interface Props extends BoardContextValueEntity {
  boardColumns?: BoardEntity[];
  dataSource?: DataSourceEntity[];
  keyHeaderLabel?: string;
  renderLabelGroup?: (item: any) => JSX.Element | React.ReactNode;
  onCardMove?: (source: DragResultEntity, destination: DragResultEntity, cardItem: any) => void;
}

export default function index(props: Props) {
  const {
    boardColumns = [],
    dataSource = [],
    keyHeaderLabel = 'code',
    renderLabelGroup,
    keyBodyCard,
    keyTitleBadgeCard,
    keyTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    renderTitleCard,
    makeColorBadgeCard,
    onCardMove,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [dataOnDrawer, setDataOnDrawer] = useState(undefined);
  const [dataParentOnDrawer, setDataParentOnDrawer] = useState(undefined);

  function HeaderPanel(row: any): React.ReactNode {
    if (renderLabelGroup) return renderLabelGroup(row);
    return row[keyHeaderLabel];
  }

  async function onDragEnd(result: any): Promise<void> {
    const destinationRaw: any = JSON.parse(atob(result.destination.droppableId));
    const sourceRaw: any = JSON.parse(atob(result.source.droppableId));
    const cardItem: any = JSON.parse(atob(result.draggableId));

    const destination: DragResultEntity = {
      index: result.destination.index,
      cardGroup: destinationRaw.groupItem,
      column: destinationRaw.itemBoardColumn,
    };

    const source: DragResultEntity = {
      index: result.source.index,
      cardGroup: sourceRaw.groupItem,
      column: sourceRaw.itemBoardColumn,
    };

    setLoading(true);
    if (onCardMove) await onCardMove(source, destination, cardItem);
    setLoading(false);
  }

  function onShowCard(card: any, parentCard: any): void {
    setShowDrawer(true);
    setDataOnDrawer(card);
    setDataParentOnDrawer(parentCard);
  }

  function onCloseShowCard(card: any, parentCard: any): void {
    setShowDrawer(false);
    setDataOnDrawer(undefined);
    setDataParentOnDrawer(undefined);
  }

  function onDeleteCard(card: any, parentCard: any): void {
    console.log({ card, parentCard });
  }

  const contextValue: BoardContextValueEntity = {
    keyTitleCard,
    keyBodyCard,
    keyTitleBadgeCard,
    renderTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    makeColorBadgeCard,
    onShowCard,
    onCloseShowCard,
    onDeleteCard,
    dataOnDrawer,
    dataParentOnDrawer,
  };

  return (
    <div className="eigen-kanban">
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <BoardProvider value={contextValue}>
          <Row>
            <Col span={showDrawer ? 16 : 24}>
              <div className="eigen-board">
                <HeaderBoard boardColumns={boardColumns} />
                <div className="card-area">
                  <DragDropContext onDragEnd={onDragEnd} onDragUpdate={(value) => console.log({ value })}>
                    <Collapse bordered={false}>
                      <SeparatorBody boardColumns={boardColumns} />
                      {dataSource.map((item, index) => {
                        return (
                          <Panel header={HeaderPanel(item)} key={item.id ?? index}>
                            <ColumnBoard groupItem={item} cards={item.cards} boardColumns={boardColumns} />
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </DragDropContext>
                </div>
              </div>
            </Col>
            {showDrawer && (
              <Col span={8}>
                <div className="eigen-drawer-board">
                  <HeaderDrawer />
                </div>
              </Col>
            )}
          </Row>
        </BoardProvider>
      </Spin>
    </div>
  );
}
