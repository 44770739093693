import React from 'react';
import { BoardEntity, CardEntity } from '../../entities';
import './column-board.scss';
import Card from '../card';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import * as _ from 'lodash';
import { useBoardContextValue } from '../../context';

interface Props {
  boardColumns: BoardEntity[];
  cards: CardEntity[];
  groupItem: any;
}

export default function index(props: Props) {
  const { boardColumns = [], cards = [], groupItem } = props;
  const { onShowCard } = useBoardContextValue();

  return (
    <div className="column-board">
      {boardColumns.map((itemBoardColumn, indexBoardColumn) => {
        return (
          <div key={itemBoardColumn.id ?? indexBoardColumn} className="column-board-wrapper">
            <div className="separator-column"></div>
            <Droppable
              droppableId={btoa(JSON.stringify({ itemBoardColumn, groupItem: _.omit(groupItem, 'cards') }))}
              key={indexBoardColumn}
            >
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={`column-board-item-wrapper `}>
                    <div className={`column-board-item ${snapshot.isDraggingOver ? 'column-board-dragging-over' : ''}`}>
                      {cards.map((cardItem, indexCard) => {
                        if (cardItem.board_column_id === itemBoardColumn.id) {
                          return (
                            <div className="card-item" key={cardItem.id ?? indexCard}>
                              <Draggable
                                key={cardItem.id}
                                draggableId={btoa(JSON.stringify(cardItem))}
                                index={indexCard}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`card-item-wrapper `}
                                      style={provided.draggableProps.style}
                                      onClick={() => onShowCard(cardItem, groupItem)}
                                    >
                                      <Card cardItem={cardItem} groupItem={groupItem} />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              }}
            </Droppable>
          </div>
        );
      })}
    </div>
  );
}
