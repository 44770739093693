import React, { useState } from 'react';
import Board from './eigen-kanban';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
const boardColumns = [
  { id: uuidV4(), title: 'BACKLOG' },
  { id: uuidV4(), title: 'TODO' },
  { id: uuidV4(), title: 'FIXING' },
  { id: uuidV4(), title: 'IN PROGRESS' },
  { id: uuidV4(), title: 'COMMIT' },
  { id: uuidV4(), title: 'REVIEW' },
  { id: uuidV4(), title: 'PIPELINE' },
  { id: uuidV4(), title: 'USER TESTING' },
  { id: uuidV4(), title: 'DONE' },
];

const dataSource = [
  {
    id: uuidV4(),
    date: moment().add(1, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        title: 'Card 1',
        code: 'WO01[2-220400001]',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[3].id,
        title: 'Card 2',
        code: 'WO02[2-220400002]',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(2, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO03[2-220400003]',

        title: 'Card 3',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO04[2-220400004]',

        title: 'Card 4',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(3, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[2].id,
        code: 'WO05[2-220400005]',

        title: 'Card 5',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[2].id,
        code: 'WO06[2-220400006]',

        title: 'Card 6',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(4, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[4].id,
        code: 'WO07[2-220400007]',

        title: 'Card 7',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO08[2-220400008]',
        title: 'Card 8',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(5, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO09[2-220400009]',
        title: 'Card 9',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO10[2-220400010]',
        title: 'Card 10',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
];

export default function index() {
  const [data, setData] = useState(dataSource);
  async function onCardMove(source: any, destination: any, cardItem: any) {
    return await new Promise((resolve) => {
      setTimeout(() => {
        const newDataSource = data
          .map((item) => {
            if (item.id !== source.cardGroup.id) return item;

            return {
              ...item,
              cards: item.cards.filter((itemCard) => itemCard.id !== cardItem.id),
            };
          })
          .map((item) => {
            if (item.id !== destination.cardGroup.id) return item;
            const newItem = item;
            newItem.cards.splice(destination.index, 0, { ...cardItem, board_column_id: destination.column.id });
            return newItem;
          });

        setData(newDataSource);
        resolve(true);
      }, 1000);
    });
  }
  return (
    <React.Fragment>
      <Board
        boardColumns={boardColumns}
        keyHeaderLabel="date"
        dataSource={data}
        onCardMove={onCardMove}
        renderBodyCard={(cardItem: any) => <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>}
      />
    </React.Fragment>
  );
}
