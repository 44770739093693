export const capitalizeEachWord = (s) => {
  if (typeof s !== 'string') return '';

  const newStringSplit = s.split(' ');
  const newString = newStringSplit.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });

  return newString.join(' ');
};
