import React from 'react';
import { HashRouter as RouterProvider, Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '@main/layouts/auth-layout';
import Dashboard from '@pages/dashboard';
import QueryBuilder from '@pages/dashboard/form';
import Kanban from '@pages/kanban';
import KanbanV2 from '@pages/kanban-v2';
import DnD from '@pages/dnd';

function LayoutComponent({ children }: { children?: any }) {
  return <AuthLayout>{children}</AuthLayout>;
}

export function AppRouter(): JSX.Element {
  return (
    <RouterProvider>
      <Switch>
        <Route exact path="/" component={() => <Redirect to="/dashboard" />} />

        <Route
          exact
          path="/dashboard"
          component={() => (
            <LayoutComponent>
              <Dashboard />
            </LayoutComponent>
          )}
        />
        <Route
          exact
          path="/dashboard/:project/:action"
          component={() => (
            <LayoutComponent>
              <QueryBuilder />
            </LayoutComponent>
          )}
        />
        <Route
          exact
          path="/dashboard/:project/:action/:id"
          component={() => (
            <LayoutComponent>
              <QueryBuilder />
            </LayoutComponent>
          )}
        />

        <Route
          exact
          path="/kanban"
          component={() => (
            <LayoutComponent>
              <Kanban />
            </LayoutComponent>
          )}
        />
        <Route
          exact
          path="/kanban-v2"
          component={() => (
            <LayoutComponent>
              <KanbanV2 />
            </LayoutComponent>
          )}
        />
        <Route
          exact
          path="/dnd"
          component={() => (
            <LayoutComponent>
              <DnD />
            </LayoutComponent>
          )}
        />

        <Route exact path="/*" component={() => <div>404-Not Found.</div>} />
      </Switch>
    </RouterProvider>
  );
}
