import React, { useState } from 'react';

import DrawerDetail from './components/drawer-detail';
import KanbanGroup from './components/kanban-group';
import './kanban-style.scss';

export default function index() {
  const [showDetail, setShowDetail] = useState(false);
  const [cardContent, setCardContent] = useState(null);

  function onClickCard({ content }: { content: any }) {
    setCardContent(content);
    setShowDetail(true);
  }

  function toggleDrawerDetail() {
    setShowDetail(!showDetail);
  }

  return (
    <div className="eigen-kanban-wrapper  ">
      <div className="wrapper-group-kanban">
        <KanbanGroup
          withHeaderColumn={true}
          showDetail={showDetail}
          onClickCard={onClickCard}
          cardContent={cardContent}
          toggleDrawerDetail={toggleDrawerDetail}
        />
      </div>

      <DrawerDetail cardContent={cardContent} visible={showDetail} onClose={() => toggleDrawerDetail()} />
    </div>
  );
}
