import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Divider, Row, Col, Tag } from 'antd';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  CalendarOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import './style.scss';
import { contextType } from 'react-grid-layout';
import moment from 'moment';
import { capitalizeEachWord } from '@base/helpers';

interface Props {
  visible?: boolean;
  onClose?(): void;
  cardContent?: any;
}
export default function index(props: Props) {
  const { visible, onClose, cardContent } = props;
  function onDrawerClose() {
    if (onClose) onClose();
  }

  function menuAction(content: any): JSX.Element {
    return (
      <Menu style={{ width: '200px' }} onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item icon={<EditOutlined />} key="0" onClick={(info) => console.log({ info })}>
          Edit
        </Menu.Item>
        <Menu.Item icon={<DeleteOutlined />} key="1" onClick={(info) => console.log({ info })}>
          Delete
        </Menu.Item>
        <Menu.Item icon={<CopyOutlined />} key="3" onClick={(info) => console.log({ info })}>
          Duplicate
        </Menu.Item>
      </Menu>
    );
  }

  function makeColorBadge(type: string): string {
    if (type === 'bug') return '#f5222d';
    else if (type === 'story') return '#13c2c2';
    return '#108ee9';
  }

  return (
    <div className={`detail-card-wrapper  ${visible ? '' : 'detail-card-wrapper-hidden'}`}>
      <div className="detail-card-header">
        <Row>
          <Col span={20}>
            <div className="title-content-wrapper">
              <div className="title">{cardContent?.code}</div>
            </div>
          </Col>
          <Col span={2}>
            <Dropdown overlay={() => menuAction(cardContent)} trigger={['click']}>
              <Button size="large" type="text" icon={<EllipsisOutlined />}></Button>
            </Dropdown>
          </Col>
          <Col span={1}>
            <Button size="large" type="text" icon={<CloseOutlined />} onClick={() => onDrawerClose()} />
          </Col>
        </Row>
        <Divider style={{ margin: '0 0' }} />
      </div>

      <div className="detail-card-body">
        <div className="title-content-wrapper">
          <div className="title">{cardContent?.title}</div>

          <div className="detail-body-item">
            <Row>
              <Col span={4}>
                <div className="item-title">Assignee</div>
              </Col>
              <Col span={20}>
                <div className="item-content">{cardContent?.assignee && cardContent.assignee}</div>
              </Col>
            </Row>
          </div>

          <div className="detail-body-item">
            <Row>
              <Col span={4}>
                <div className="item-title">Due date</div>
              </Col>
              <Col span={20}>
                <div className="item-content">
                  <CalendarOutlined style={{ marginRight: '10px' }} />

                  {cardContent?.due_date && moment(cardContent.due_date).format('ll')}
                </div>
              </Col>
            </Row>
          </div>

          <div className="detail-body-item">
            <Row>
              <Col span={4}>
                <div className="item-title">Status</div>
              </Col>
              <Col span={20}>
                <div className="item-content">{cardContent?.status && cardContent.status}</div>
              </Col>
            </Row>
          </div>

          <div className="detail-body-item">
            <Row>
              <Col span={4}>
                <div className="item-title">Priority</div>
              </Col>
              <Col span={20}>
                <div className="item-content">{cardContent?.priority && cardContent.priority}</div>
              </Col>
            </Row>
          </div>
          <div className="detail-body-item">
            <Row>
              <Col span={4}>
                <div className="item-title">Type</div>
              </Col>
              <Col span={20}>
                <div className="item-content">
                  {cardContent?.type && (
                    <Tag color={makeColorBadge(cardContent?.type)}>{capitalizeEachWord(cardContent.type)}</Tag>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          <div className="detail-body-item">
            <div className="item-title">Description</div>
            <div className="item-content-description">{cardContent?.description}</div>
          </div>
        </div>
      </div>
      {/* <div className="detail-card-footer">
        <Divider style={{ margin: '0 0' }} />
        <div className="detail-card-footer-content">
          <div>sdskjds</div>
        </div>
      </div> */}
    </div>
  );
}
