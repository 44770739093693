import moment from 'moment';

interface IDateFormatter {
  toLocal(format?: string): string;
  toUtc(format?: string): string;
  isValid(): boolean;
  getDate(): any;
}

class DateFormatter implements IDateFormatter {
  constructor(private date: string, private format: string = 'DD-MM-YYYY, HH:mm:ss') {}
  getDate(): any {
    return this.date ? moment(new Date(this.date)) : null;
  }

  isValid(): boolean {
    const isValidMoment = moment(this.date, true).isValid();
    return isValidMoment && this.date ? true : false;
  }

  toLocal(format?: string): string {
    const isValidDate = this.isValid();
    const formatDate = format ?? this.format;
    const date = this.getDate();
    return isValidDate && date ? date.local().format(formatDate) : '';
  }

  toUtc(format?: string): string {
    const isValidDate = this.isValid();
    const formatDate = format ?? this.format;
    const date = this.getDate();
    return isValidDate && date ? date.utc().format(formatDate) : '';
  }
}

export function dateFormatter(date: string): IDateFormatter {
  return new DateFormatter(date);
}
