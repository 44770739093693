import React from 'react';
import { Row, Col, Button } from 'antd';
import { Menu, Dropdown } from 'antd';
import { EditOutlined, EllipsisOutlined, DeleteOutlined, CopyOutlined, CloseOutlined } from '@ant-design/icons';
import { useBoardContextValue } from '../../context';
import './drawer-header.scss';

export default function index() {
  const {
    dataOnDrawer,
    dataParentOnDrawer,
    onCloseShowCard,
    keyTitleCard = 'code',
    renderTitleCard,
  } = useBoardContextValue();

  function TitleCard(row: any): React.ReactNode {
    if (renderTitleCard) return renderTitleCard(row);
    return row[keyTitleCard];
  }

  function menuAction(content: any): JSX.Element {
    return (
      <Menu style={{ width: '200px' }} onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item icon={<EditOutlined />} key="0" onClick={(info) => console.log({ info })}>
          Edit
        </Menu.Item>
        <Menu.Item icon={<DeleteOutlined />} key="1" onClick={(info) => console.log({ info })}>
          Delete
        </Menu.Item>
        <Menu.Item icon={<CopyOutlined />} key="3" onClick={(info) => console.log({ info })}>
          Duplicate
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className="header-drawer-board">
      <Row>
        <Col span={20}>
          <div className="title">{TitleCard(dataOnDrawer)}</div>
        </Col>
        <Col span={2}>
          <Dropdown overlay={() => menuAction(dataOnDrawer)} trigger={['click']}>
            <Button size="small" type="text" icon={<EllipsisOutlined style={{ fontSize: '14px' }} />}></Button>
          </Dropdown>
        </Col>
        <Col span={2}>
          <Button
            size="small"
            type="text"
            icon={
              <CloseOutlined
                style={{ fontSize: '14px' }}
                onClick={() => onCloseShowCard(dataOnDrawer, dataParentOnDrawer)}
              />
            }
          ></Button>
        </Col>
      </Row>
    </div>
  );
}
