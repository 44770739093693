import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PlaySquareOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
const { Text } = Typography;
import { Button, CubeLoader, FatalError } from '../../atoms';

import TableRender from './charts/table';
import LineRender from './charts/line';
import AreaRender from './charts/area';
import BarRender from './charts/bar';
import PieRender from './charts/pie';
import IndicatorRender from './charts/indicator';

import cubejs from '@cubejs-client/core';
import * as _ from 'lodash';

const Positioner = styled.div`
  min-height: 400px;
  max-height: 800px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

type TChartContainerProps = {
  invisible: boolean;
};

const ChartContainer = styled.div<TChartContainerProps>`
  visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
  min-height: 400px;

  & > iframe {
    width: 100%;
    min-height: 400px;
    border: none;
  }
`;

const RequestMessage = styled.div`
  position: absolute;
  width: 100%;
  bottom: -4em;
  animation: fadeIn 0.3s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

const API_URL = `${process.env.REACT_APP_BASE_CUBE_API_URL}/v1`;
const CUBEJS_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDUwOTY2OTksImV4cCI6MTY0NTE4MzA5OX0.1NJFhyFBja7Q5habz5tg_YSFHtG1qE3Zi7GD-yRLaHA';

const cubejsApi = cubejs(CUBEJS_TOKEN, { apiUrl: API_URL });

export default function ChartBuilder(props: any) {
  const { chartType, query } = props;
  const [isReadyToShow, setReadyToShow] = useState(false);
  const [isLoadingRun, setIsLoadingRun] = useState(false);

  const [errorQuery, setErrorQuery] = useState(null);
  const [resultQuery, setResultQuery] = useState(null);

  async function handleRun(): Promise<void> {
    setIsLoadingRun(true);
    await cubejsApi
      // .load(_.omit(query, 'filters'))
      .load(query)
      .then((resultSet) => {
        setIsLoadingRun(false);
        setReadyToShow(true);
        setResultQuery(resultSet);
      })
      .catch((error) => {
        setIsLoadingRun(false);
        setReadyToShow(false);
        setErrorQuery(error?.toString());
      });
  }

  useEffect(() => {
    setReadyToShow(false);
    setErrorQuery(null);
    setResultQuery(null);
  }, [props.query]);

  return (
    <ChartContainer invisible={false}>
      {errorQuery && (
        <Centered>
          <FatalError error={errorQuery ?? ''} />
        </Centered>
      )}
      {!isLoadingRun && !isReadyToShow && (
        <Positioner>
          <Centered>
            <Button
              data-testid="run-query-btn"
              size="large"
              type="primary"
              icon={<PlaySquareOutlined />}
              onClick={() => handleRun()}
            >
              Run
            </Button>
          </Centered>
        </Positioner>
      )}

      {isLoadingRun && (
        <Positioner>
          <Centered>
            <Wrapper>
              <CubeLoader full={false} />
            </Wrapper>
          </Centered>
        </Positioner>
      )}

      {chartType === 'table' && isReadyToShow && resultQuery && (
        <TableRender isReadyToShow={isReadyToShow} resultResponseQuery={resultQuery} {...props} />
      )}
      {chartType === 'line' && isReadyToShow && resultQuery && (
        <LineRender isReadyToShow={isReadyToShow} resultResponseQuery={resultQuery} {...props} />
      )}
      {chartType === 'area' && isReadyToShow && resultQuery && (
        <AreaRender isReadyToShow={isReadyToShow} resultResponseQuery={resultQuery} {...props} />
      )}
      {chartType === 'bar' && isReadyToShow && resultQuery && (
        <BarRender isReadyToShow={isReadyToShow} resultResponseQuery={resultQuery} {...props} />
      )}
      {chartType === 'pie' && isReadyToShow && resultQuery && (
        <PieRender isReadyToShow={isReadyToShow} resultResponseQuery={resultQuery} {...props} />
      )}
      {chartType === 'indicator' && isReadyToShow && resultQuery && (
        <IndicatorRender isReadyToShow={isReadyToShow} resultResponseQuery={resultQuery} {...props} />
      )}
    </ChartContainer>
  );
}
