import React, { useState } from 'react';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { QueryBuilder } from '@cubejs-client/react';
import styled from 'styled-components';
import { Col, Row, Space } from 'antd';
import { Card, FatalError } from './atoms';
import SectionHeader from './components/SectionHeader';
import SectionRow from './components/SectionRow';
import SelectChartType from './components/SelectChartType';

import MemberGroup from './components/MemberGroup';
import GlobalStyles from './components/GlobalStyles';
import TimeGroup from './components/TimeGroup';
import FilterGroup from './components/FilterGroup';
import ChartRender from './components/chart-renders';

import { Select } from 'antd';
const { Option } = Select;

const API_URL = `${process.env.REACT_APP_BASE_CUBE_API_URL}/v1`;
const CUBEJS_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDUwOTY2OTksImV4cCI6MTY0NTE4MzA5OX0.1NJFhyFBja7Q5habz5tg_YSFHtG1qE3Zi7GD-yRLaHA';

const cubejsApi = cubejs(CUBEJS_TOKEN, { apiUrl: API_URL });

const Wrapper = styled.div`
  background-color: var(--layout-body-background);
  padding-bottom: 16px;
`;

const Section = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 24px;
  margin-bottom: 16px;

  > *:first-child {
    margin-bottom: 8px;
  }
`;

interface Props {
  defaultValue?: any;
  additionalFilter?: any;
  onChange?(prop: any): void;
  defaultFilterModule?: any;
}

export default function index(props: Props) {
  const { defaultValue, onChange, defaultFilterModule, additionalFilter } = props;

  const [filterModule, setFilterModule] = useState(undefined);

  function onChangeRender(props: any): void {
    if (onChange) onChange(props);
  }

  function filterAvailableModule(available, member) {
    if (!available || available?.length === 0) return member;
    return member.filter((item) => available.includes(item.cubeTitle));
  }

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <GlobalStyles />
      <QueryBuilder
        key={JSON.stringify([defaultValue])}
        defaultChartType={defaultValue?.chartType || 'table'}
        initialVizState={defaultValue}
        cubejsApi={cubejsApi}
        render={(props) => {
          const {
            query,
            error,
            metaError,
            meta,
            isQueryPresent,
            chartType,
            updateChartType,
            measures,
            updateMeasures,
            dimensions,
            updateDimensions,
            segments,
            updateSegments,
            filters,
            updateFilters,
            timeDimensions,
            updateTimeDimensions,
            orderMembers,
            updateOrder,
            pivotConfig,
            updatePivotConfig,
            missingMembers,
            isFetchingMeta,
            dryRunResponse,
            availableMembers,
            availableFilterMembers,
          } = props;

          onChangeRender({ ...(props ?? {}), filterModule });

          let parsedDateRange;
          if (dryRunResponse) {
            const { timeDimensions = [] } = dryRunResponse.pivotQuery || {};
            parsedDateRange = timeDimensions[0]?.dateRange;
          } else if (Array.isArray(query.timeDimensions?.[0]?.dateRange)) {
            parsedDateRange = query.timeDimensions[0].dateRange;
          }

          const measuresMember = availableMembers?.measures || [];
          const dimensionsMember = availableMembers?.dimensions || [];
          const availableModuleMember: string[] = [];

          measuresMember.forEach((item) => {
            const isExist = availableModuleMember.find((itemModule) => itemModule === item.cubeTitle);
            if (!isExist) availableModuleMember.push(item.cubeTitle);
          });

          dimensionsMember.forEach((item) => {
            const isExist = availableModuleMember.find((itemModule) => itemModule === item.cubeTitle);
            if (!isExist) availableModuleMember.push(item.cubeTitle);
          });

          return (
            <Wrapper data-testid={`query-builder`}>
              <Row justify="space-around" align="top" gutter={24} style={{ marginBottom: 12 }}>
                <Col span={24}>
                  <Card bordered={false} style={{ borderRadius: 0 }}>
                    <Row align="top" gutter={0} style={{ marginBottom: -12 }}>
                      <Section>
                        <SectionHeader>Filter Module</SectionHeader>
                        <Select
                          mode="multiple"
                          value={filterModule}
                          defaultValue={defaultFilterModule}
                          onChange={setFilterModule}
                          style={{ minWidth: 200 }}
                          placeholder="Choose module"
                        >
                          {availableModuleMember.map((item) => (
                            <Option key={item}>{item}</Option>
                          ))}
                        </Select>
                      </Section>

                      <Section>
                        <SectionHeader>Measures</SectionHeader>
                        <MemberGroup
                          disabled={isFetchingMeta}
                          members={measures}
                          availableMembers={filterAvailableModule(
                            filterModule ?? defaultFilterModule,
                            availableMembers?.measures || [],
                          )}
                          missingMembers={missingMembers}
                          addMemberName="Measure"
                          updateMethods={updateMeasures}
                        />
                      </Section>

                      <Section>
                        <SectionHeader>Dimensions</SectionHeader>
                        <MemberGroup
                          disabled={isFetchingMeta}
                          members={dimensions}
                          availableMembers={filterAvailableModule(
                            filterModule ?? defaultFilterModule,
                            availableMembers?.dimensions || [],
                          )}
                          missingMembers={missingMembers}
                          addMemberName="Dimension"
                          updateMethods={updateDimensions}
                        />
                      </Section>

                      <Section>
                        <SectionHeader>Segment</SectionHeader>
                        <MemberGroup
                          disabled={isFetchingMeta}
                          members={segments}
                          availableMembers={filterAvailableModule(
                            filterModule ?? defaultFilterModule,
                            availableMembers?.segments || [],
                          )}
                          missingMembers={missingMembers}
                          addMemberName="Segment"
                          updateMethods={updateSegments}
                        />
                      </Section>

                      <Section>
                        <SectionHeader>Time</SectionHeader>
                        <TimeGroup
                          disabled={isFetchingMeta}
                          members={timeDimensions}
                          availableMembers={filterAvailableModule(
                            filterModule ?? defaultFilterModule,
                            availableMembers?.timeDimensions || [],
                          )}
                          missingMembers={missingMembers}
                          addMemberName="Time"
                          updateMethods={updateTimeDimensions}
                          parsedDateRange={parsedDateRange}
                        />
                      </Section>

                      <Section>
                        <SectionHeader>Filters</SectionHeader>
                        <FilterGroup
                          disabled={isFetchingMeta}
                          members={filters}
                          availableMembers={filterAvailableModule(
                            filterModule ?? defaultFilterModule,
                            availableFilterMembers || [],
                          )}
                          missingMembers={missingMembers}
                          addMemberName="Filter"
                          updateMethods={updateFilters}
                        />
                      </Section>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row justify="space-around" align="top" style={{ margin: 0 }}>
                <Col span={24}>
                  <ChartRender
                    {...props}
                    defaultValue={defaultValue}
                    additionalFilter={additionalFilter}
                    availableFilters={filterAvailableModule(
                      filterModule ?? defaultFilterModule,
                      availableFilterMembers || [],
                    )}
                  />
                </Col>
              </Row>
            </Wrapper>
          );
        }}
      />
    </CubeProvider>
  );
}
