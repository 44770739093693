export const OptionsModuleMJS = [
  { label: 'MJS Sales', name: 'mjs-sales' },
  { label: 'MJS Purchase', name: 'mjs-purchase' },
  { label: 'MJS Inventory', name: 'mjs-inventory' },
  { label: 'MJS Production', name: 'mjs-production' },
  {
    label: 'MJS Accounting Activity',
    name: 'mjs-accounting-activity',
  },
  {
    label: 'MJS Accounting Journal Item',
    name: 'mjs-accounting-journal-item',
  },
  {
    label: 'MJS Accounting Bank And Cash',
    name: 'mjs-accounting-bank-and-cash',
  },
  {
    label: 'MJS Accounting Sales Activity',
    name: 'mjs-accounting-sales-activity',
  },
  {
    label: 'MJS Accounting Sales Journal',
    name: 'mjs-accounting-sales-journal',
  },
  {
    label: 'MJS Accounting Journal Miscellaneous',
    name: 'mjs-accounting-journal-miscellaneous',
  },
  {
    label: 'MJS Accounting Purchase Activity',
    name: 'mjs-accounting-purchase-activity',
  },
  {
    label: 'MJS Accounting Purchase Journal',
    name: 'mjs-accounting-purchase-journal',
  },
  {
    label: 'MJS Accounting General Ledgers',
    name: 'mjs-accounting-general-ledgers',
  },
  {
    label: 'MJS Accounting Partner Ledgers',
    name: 'mjs-accounting-partner-ledgers',
  },
];

export const OptionsModule = [
  { label: 'Sales', name: 'sales' },
  { label: 'Purchase', name: 'purchase' },
  { label: 'Inventory', name: 'inventory' },
  { label: 'Production', name: 'production' },
  {
    label: 'Accounting Activity',
    name: 'accounting-activity',
  },
  {
    label: 'Accounting Journal Item',
    name: 'accounting-journal-item',
  },
  {
    label: 'Accounting Bank And Cash',
    name: 'accounting-bank-and-cash',
  },
  {
    label: 'Accounting Sales Activity',
    name: 'accounting-sales-activity',
  },
  {
    label: 'Accounting Sales Journal',
    name: 'accounting-sales-journal',
  },
  {
    label: 'Accounting Journal Miscellaneous',
    name: 'accounting-journal-miscellaneous',
  },
  {
    label: 'Accounting Purchase Activity',
    name: 'accounting-purchase-activity',
  },
  {
    label: 'Accounting Purchase Journal',
    name: 'accounting-purchase-journal',
  },
  {
    label: 'Accounting General Ledgers',
    name: 'accounting-general-ledgers',
  },
  {
    label: 'Accounting Partner Ledgers',
    name: 'accounting-partner-ledgers',
  },
];
