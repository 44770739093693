import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Input, Select, Button, Modal, notification, Switch, Row, Col, Card } from 'antd';
const { Option } = Select;

import { PageHeader } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import QueryBuilder from '@base/components/query-builder';
import { OptionsModule, OptionsModuleMJS } from './options';

export default function index() {
  const history = useHistory();
  const params = useParams<any>();
  const paramsId = params.id;
  const paramsAction = params.action;
  const project = params.project;

  const isUpdate = paramsAction === 'update';
  const isDuplicate = paramsAction === 'duplicate';

  const [form] = Form.useForm();
  const [additionalFilter, setAdditionalFilter] = useState([]);
  const [optionsModuleTarget, setOptionsModuleTarget] = useState([]);

  useEffect(() => {
    if (project === 'mjs') setOptionsModuleTarget(OptionsModuleMJS);
    else if (project === 'pmps') setOptionsModuleTarget(OptionsModule);
    else setOptionsModuleTarget([]);
  }, [project]);

  let titlePage = '';
  if (isUpdate) titlePage = 'Update Chart';
  else if (isDuplicate) titlePage = 'DUplicate Chart';
  else titlePage = 'Create Chart';

  const [show, setShow] = useState(null);
  async function getData() {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/report/${paramsId}/detail`)
      .then((response) => response.json())
      .then((data) => {
        const newData = { ...data, chartType: 'table' };
        setShow(newData);
        setAdditionalFilter(newData.additionalFilter ?? []);
        form.setFieldsValue(newData);
      });
  }

  async function handleCreate({ cleanVal }: { cleanVal: any }) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer my-token',
        'My-Custom-Header': 'foobar',
      },
      body: JSON.stringify(cleanVal),
    };
    await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/report`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        notification.success({
          message: 'Successfully created data.',
          duration: 4,
        });
        handleBack();
      });
  }

  async function handleUpdate({ cleanVal, id }: { cleanVal: any; id: string }) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer my-token',
        'My-Custom-Header': 'foobar',
      },
      body: JSON.stringify(cleanVal),
    };
    await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/report/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        notification.success({
          message: 'Successfully updated data.',
          duration: 4,
        });

        handleBack();
      })
      .catch((error) => {
        notification.error({
          message: 'Failed.',
          duration: 4,
        });
      });
  }

  async function handleSave() {
    try {
      const cleanVal = await form.validateFields();
      if (isUpdate) await handleUpdate({ cleanVal, id: paramsId });
      else await handleCreate({ cleanVal });
    } catch (error) {}
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (params.id) {
      getData();
    } else {
      form.setFieldsValue({ contentType: 'report' });
    }
  }, [params]);

  function onChangeQueryBuilder(props: any): void {
    form.setFieldsValue(props);
  }

  async function triggerSaveConfirmation() {
    try {
      await form.validateFields();
      Modal.confirm({
        title: 'Confirmation',
        icon: <ExclamationCircleOutlined />,
        content: 'Do you want to save changes?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => handleSave(),
      });
    } catch (error) {}
  }

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        onBack={() => handleBack()}
        title={titlePage}
        extra={[
          <Button key="1" type="primary" onClick={triggerSaveConfirmation}>
            Save
          </Button>,
        ]}
      />
      <div style={{ marginBottom: '20px' }}></div>
      <Form
        form={form}
        layout="vertical"
        name="form-query-builder"
        onValuesChange={(item, row) => setAdditionalFilter(row?.additionalFilter)}
      >
        <Card>
          <Form.Item name="pivotConfig" hidden noStyle />
          <Form.Item name="query" hidden noStyle />
          <Form.Item name="chartType" hidden noStyle />
          <Form.Item name="contentType" hidden noStyle />
          <Form.Item name="filterModule" hidden noStyle />
          <Row gutter={15}>
            <Col span={4}>
              <Form.Item name="module" label="Module" rules={[{ required: true, message: 'Module is required.' }]}>
                <Select placeholder="choose" allowClear>
                  {optionsModuleTarget.map((item, index) => {
                    return (
                      <Option key={index} value={item.name}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="menu_label"
                label="Menu Label"
                rules={[{ required: true, message: 'Menu label is required.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="name"
                label="Content Name"
                rules={[{ required: true, message: 'Content name is required.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="notes" label="Notes">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="showToDashBoard" label="Show to Dashboard" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <div style={{ marginBottom: '20px' }}></div>

        <QueryBuilder
          defaultValue={show}
          onChange={onChangeQueryBuilder}
          defaultFilterModule={show?.filterModule}
          additionalFilter={additionalFilter}
        />
      </Form>
    </React.Fragment>
  );
}
