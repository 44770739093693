import React, { useEffect, useState } from 'react';
import { Table, Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { Popconfirm, Switch } from 'antd';

export default function ModuleData({
  moduleName,
  activeTabMenuProject,
}: {
  moduleName: string;
  activeTabMenuProject: string;
}) {
  const history = useHistory();

  const [data, setData] = useState([]);
  async function getData() {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/report/${moduleName}`)
      .then((response) => response.json())
      .then((data) => setData(data));
  }

  async function deleteData(id: string) {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/report/${id}`, {
      method: 'DELETE',
    })
      .then((response) => {
        notification.success({
          message: 'Successfully deleted data.',
          duration: 4,
        });
        getData();

        return response.json();
      })
      .then((data) => {
        notification.success({
          message: 'Successfully deleted data.',
          duration: 4,
        });
        getData();
      });
  }

  useEffect(() => {
    getData();
  }, []);

  function handleUpdate(row) {
    history.push(`/dashboard/${activeTabMenuProject}/update/${row?._id ?? row?.id}`);
  }

  function handleDuplicate(row) {
    history.push(`/dashboard/${activeTabMenuProject}/duplicate/${row?._id ?? row?.id}`);
  }

  function renderAction(item: any, row: any): JSX.Element {
    return (
      <React.Fragment>
        <Tooltip placement="bottom" title="Update">
          <Button size="small" type="text" onClick={() => handleUpdate(row)}>
            <EditOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" title="Duplicate">
          <Button size="small" type="text" onClick={() => handleDuplicate(row)}>
            <CopyOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" title="Update">
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteData(row?.id ?? row?._id)}
          >
            <Button size="small" type="text">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Tooltip>
      </React.Fragment>
    );
  }
  const columns = [
    {
      title: 'Action',
      render: renderAction,
      width: 100,
    },
    {
      title: 'Module',
      dataIndex: 'module',
    },
    {
      title: 'Menu Label',
      dataIndex: 'menu_label',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Show to Dashboard',
      dataIndex: 'showToDashBoard',
      render: (item) => (item ? 'Yes' : 'No'),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
  ];

  return <Table columns={columns} dataSource={data} />;
}
