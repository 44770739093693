export const DataStatusOptions = [
  'draft',
  'active',
  'inactive',
  'requested',
  'open',
  'declined',
  'deleted',
  'waiting',
  'cancel',
  'done',
  'in_process',
  'closed',
  'todo',
  'on_hold',
  'blocked',
  'blacklist',
];
export const TypeOptions = [
  { label: 'Select', value: 'select' },
  { label: 'Input Text', value: 'input_text' },
  { label: 'Input Number', value: 'input_number' },
  { label: 'Input Tag', value: 'input_tag' },
  { label: 'Date Picker', value: 'date_picker' },
  { label: 'Date Range Picker', value: 'date_range_picker' },
];

export const DataSourceOptions = [
  { label: 'Custom options', value: 'custom_options' },
  { label: 'Status Options', value: 'status_options' },

  //Company
  { label: 'Data company', value: 'v1/company' },
  { label: 'Data site', value: 'v1/site' },
  { label: 'Data location', value: 'v1/locations' },
  { label: 'Data branch', value: 'v1/branches' },

  //Contact
  { label: 'Data user', value: 'v1/auth' },
  { label: 'Data contact', value: 'v2/contacts' },
  { label: 'Data bank', value: 'v1/bank' },
  { label: 'Data project type', value: 'v1/project/type' },

  //Sales
  { label: 'Data sales customer group', value: 'v1/sales/customer-groups' },
  { label: 'Data sales fiscal positions', value: 'v1/sales/fiscal-positions' },
  { label: 'Data sales division', value: 'v1/sales/sales-divisions' },
  { label: 'Data sales limit', value: 'v1/sales/sales-limits' },
  { label: 'Data sales tax', value: 'v1/sales/sales-taxes' },
  { label: 'Data sales term of payment', value: 'v1/sales/term-of-payments' },
  { label: 'Data sales area', value: 'v1/sales/sales-areas' },
  { label: 'Data sales order', value: 'v1/sales/sales-orders' },
  { label: 'Data sales team', value: 'v1/sales/sales-teams' },
  { label: 'Data sales exchange product', value: 'v1/sales/exchange-products' },

  //Purchase
  { label: 'Data purchase vendor group', value: 'v1/purchases/vendor-groups' },
  { label: 'Data purchase division', value: 'v1/purchases/purchase-divisions' },
  { label: 'Data purchase limit', value: 'v1/purchases/purchase-limits' },
  { label: 'Data purchase request', value: 'v1/purchases/purchase-requests' },
  { label: 'Data purchase tax', value: 'v1/purchases/purchase-taxes' },
  { label: 'Data purchase order', value: 'v1/purchases/purchase-orders' },
  { label: 'Data purchase term of payment', value: 'v1/purchases/term-of-payments' },
  { label: 'Data purchase fiscal position', value: 'v1/purchases/fiscal-positions' },

  //Inventory
  { label: 'Data inventory brand', value: 'v1/inventory/brands' },
  { label: 'Data inventory uom', value: 'v1/inventory/units' },
  { label: 'Data inventory product category', value: 'v1/inventory/product/categories' },
  { label: 'Data inventory product master', value: 'v1/inventory/product/masters' },
  { label: 'Data inventory product variant', value: 'v1/inventory/product/variants' },
  { label: 'Data inventory product package', value: 'v1/inventory/product/packages' },
  { label: 'Data inventory batch', value: 'v1/inventory/batches' },
  { label: 'Data inventory lot', value: 'v1/inventory/lots' },
  { label: 'Data inventory request', value: 'v1/inventory/inventory-requests' },
  { label: 'Data inventory outgoing transfer', value: 'v1/inventory/outgoing-transfers' },
  { label: 'Data inventory incoming transfer', value: 'v1/inventory/incoming-transfers' },
  { label: 'Data inventory scrap', value: 'v1/inventory/scraps' },
  { label: 'Data inventory transfer orders', value: 'v1/inventory/transfer/orders' },
  { label: 'Data inventory packing slip', value: 'v1/inventory/transfer/packing-slips' },
  { label: 'Data inventory packing list', value: 'v1/inventory/transfer/packing-lists' },
  { label: 'Data inventory receipt', value: 'v1/inventory/transfer/receipts' },
  { label: 'Data inventory shipping order', value: 'v1/inventory/transfer/shipping-orders' },
  { label: 'Data inventory pack/unpack', value: 'v1/inventory/pack-unpacks' },

  //Logistic
  { label: 'Data logistic vehicle type', value: 'v1/logistic/vehicle/types' },
  { label: 'Data logistic vehicle legality', value: 'v1/logistic/vehicle/legalities' },
  { label: 'Data logistic vehicle', value: 'v1/logistic/vehicles' },
  { label: 'Data logistic driver', value: 'v1/logistic/drivers' },
  { label: 'Data logistic travel allowance', value: 'v1/logistic/travel-allowances' },

  //Production
  { label: 'Data production shift', value: 'v1/productions/shifts' },
  { label: 'Data production machine type', value: 'v1/productions/machine-types' },
  { label: 'Data production process', value: 'v1/productions/production-processes' },
  { label: 'Data production machine', value: 'v1/productions/machines' },
  { label: 'Data production work center', value: 'v1/productions/work-centers' },
  { label: 'Data production bom category', value: 'v1/productions/bom-categories' },
  { label: 'Data production route', value: 'v1/productions/routes' },
  { label: 'Data production bom', value: 'v1/productions/bill-of-materials' },
  { label: 'Data production shift planing', value: 'v1/productions/shift/plans' },
  { label: 'Data production team', value: 'v1/productions/production/teams' },
  { label: 'Data production request', value: 'v1/productions/production-requests' },
  { label: 'Data production order', value: 'v1/productions/production-orders' },
  { label: 'Data production work order', value: 'v1/productions/work-orders' },
];

export const OperatorOptions = [
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Does not contain',
    value: 'notContains',
  },
  {
    label: 'Equals',
    value: 'equals',
  },
  {
    label: 'Does not equal',
    value: 'notEquals',
  },
  {
    label: 'Start With',
    value: 'startsWith',
  },
  {
    label: 'End With',
    value: 'endsWith',
  },
  {
    label: 'Greater Than',
    value: 'gt',
  },

  {
    label: 'Greater Than or Equal To',
    value: 'gte',
  },

  {
    label: 'Less Than',
    value: 'lt',
  },
  {
    label: 'Less Than or Equal To',
    value: 'lte',
  },

  {
    label: 'Date range',
    value: 'inDateRange',
  },

  {
    label: 'Not date range',
    value: 'notInDateRange',
  },

  {
    label: 'Before date',
    value: 'beforeDate',
  },

  {
    label: 'After date',
    value: 'afterDate',
  },

  {
    label: 'Is set',
    value: 'set',
  },
  {
    label: 'Is not set',
    value: 'notSet',
  },
];
